const planToText = {
  drive: "같이 드라이브/여행",
  restaurant: "같이 맛집 가기",
  drink: "같이 술 한 잔",
  movie: "같이 영화 보기",
  concert: "같이 공연/전시 보기",
  amusementPark: "같이 놀이공원",
};
const planToEmoji = {
  drive: "🚗",
  eat: "🍽",
  drink: "🍻",
  movie: "🎬",
  concert: "🎶",
  amusementPark: "🎡",
};

const planQuestions = {
  drive: `${planToEmoji.drive} ${planToText.drive}`,
  eat: `${planToEmoji.eat} ${planToText.eat}`,
  drink: `${planToEmoji.drink} ${planToText.drink}`,
  movie: `${planToEmoji.movie} ${planToText.movie}`,
  concert: `${planToEmoji.concert} ${planToText.concert}`,
  amusementPark: `${planToEmoji.amusementPark} ${planToText.amusementPark}`,
};

const job = {
  student: "대학생",
  officeWorker: "직장인",
  postgrad: "대학원생",
  freelancer: "프리랜서",
  jobSeeker: "취준생",
};

const jobToEmoji = {
  student: "🎓",
  officeWorker: "💼",
  postgrad: "📚",
  freelancer: "🖥️",
  jobSeeker: "📑",
};

const jobQuestions = {
  student: `${jobToEmoji.student} ${job.student}`,
  officeWorker: `${jobToEmoji.officeWorker} ${job.officeWorker}`,
  postgrad: `${jobToEmoji.postgrad} ${job.postgrad}`,
  freelancer: `${jobToEmoji.freelancer} ${job.freelancer}`,
  jobSeeker: `${jobToEmoji.jobSeeker} ${job.jobSeeker}`,
};

const gender = {
  femail: "여성",
  mail: "남성",
};
const genderToEmoji = {
  femail: "‍🙎‍♀️",
  mail: "🙎‍♂️",
};
const genderQustions = {
  femail: `${genderToEmoji.femail} ${gender.femail}이에요`,
  mail: `${genderToEmoji.mail} ${gender.mail}이에요`,
};

const face = {
  dog: "강아지",
  cat: "고양이",
  rabbit: "토끼",
  fox: "여우",
  horse: "말",
  dinosaur: "공룡",
};
const faceToEmoji = {
  dog: "🐶",
  cat: "🐱",
  rabbit: "🐰",
  fox: "🦊",
  horse: "🐴",
  dinosaur: "🦖",
};
const faceQuestions = {
  dog: `${faceToEmoji.dog} ${face.dog}`,
  cat: `${faceToEmoji.cat} ${face.cat}`,
  rabbit: `${faceToEmoji.rabbit} ${face.rabbit}`,
  fox: `${faceToEmoji.fox} ${face.fox}`,
  horse: `${faceToEmoji.horse} ${face.horse}`,
  dinosaur: `${faceToEmoji.dinosaur} ${face.dinosaur}`,
};

const body = {
  thin: "마른 체형",
  normal: "보통 체형",
  slim: "슬림탄탄",
  chubby: "통통한 체형",
  muscular: "근육질",
};

const idelType = {
  dog: "강아지",
  cat: "고양이",
  rabbit: "토끼",
  fox: "여우",
  horse: "말",
  dinosaur: "공룡",
};
const idelTypeToEmoji = {
  dog: "🐶",
  cat: "🐱",
  rabbit: "🐰",
  fox: "🦊",
  horse: "🐴",
  dinosaur: "🦖",
};
const idelTypeQuestions = {
  dog: `${idelTypeToEmoji.dog} ${idelType.dog}`,
  cat: `${idelTypeToEmoji.cat} ${idelType.cat}`,
  rabbit: `${idelTypeToEmoji.rabbit} ${idelType.rabbit}`,
  fox: `${idelTypeToEmoji.fox} ${idelType.fox}`,
  horse: `${idelTypeToEmoji.horse} ${idelType.horse}`,
  dinosaur: `${idelTypeToEmoji.dinosaur} ${idelType.dinosaur}`,
};
export {
  planToText,
  planToEmoji,
  planQuestions,
  job,
  jobToEmoji,
  jobQuestions,
  gender,
  genderToEmoji,
  genderQustions,
  face,
  faceToEmoji,
  faceQuestions,
  body,
  idelType,
  idelTypeToEmoji,
  idelTypeQuestions,
};
