import React, { useState, useEffect } from "react";
import DefaultHeader from "../components/DefaultHeader.jsx";
import SizedBox from "../components/SizedBox.jsx";
import NotInsertUserInformation from "../components/NotInsertUserInformation.jsx";
import ProfileCard from "../components/ProfileCard.jsx";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase.config";
import { signOut } from "firebase/auth";
import { db } from "../firebase.config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAuth } from "../context/AuthContext.jsx";
import { Indicator } from "../components/DefaultIndicator.jsx";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#DB8580",
    height: "100vh",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    marginBottom: "20px",
  },
  logoSection: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontFamily: "LINE Seed Sans KR",
    fontWeight: "700",
  },
  icon: {
    fontSize: "24px",
  },

  footerText: {
    width: "calc(100vw - 40px)",
    color: "white",
    fontSize: 12,
    fontFamily: "Noto Sans KR",
    fontWeight: "400",
    textAlign: "left",
    // padding: "20px",
  },
  footerIcon: {
    marginRight: "5px",
  },
};

const UserInformationPage = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      if (user) {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty && querySnapshot.docs[0].data().age != null) {
          setUserData(querySnapshot.docs[0].data());
          setUserExists(true);
        } else {
          setUserExists(false);
        }
      }
      setIsLoading(false);
    };
    fetchUserData();
  }, []);

  return (
    <div style={styles.container}>
      <DefaultHeader title="프로필" />
      <SizedBox height={23} />

      {isLoading ? (
        <Indicator /> // Loading indicator
      ) : userExists ? (
        <ProfileCard userData={userData} />
      ) : (
        <NotInsertUserInformation />
      )}

      <div style={styles.footerText}>
        {userExists ? (
          <>
            👉 프로필 수정을 원하실 경우 our@justpin.io로 수정된 내용 전달
            주시면 24시간 내에 반영됩니다 :)
          </>
        ) : (
          <>
            {/* 👉 이미 프로필 입력을 완료하셨다면 잠시만 기다려주세요!
            <br />
            매일 오후 4시에 입력된 프로필이 매칭에 반영돼요. */}
          </>
        )}
      </div>
      <div style={styles.logoutSection}></div>
      <SizedBox height={265} />
      <div
        style={{
          textAlign: "center",
          color: "white",
          fontSize: 12,
          fontFamily: "LINE Seed Sans KR",
          fontWeight: "400",
          textDecoration: "underline",
          wordWrap: "break-word",
          cursor: "pointer",
        }}
        onClick={() => {
          signOut(auth)
            .then(() => {
              navigate("/home");
            })
            .catch((error) => {
              console.log(error);
            });
        }}
      >
        로그아웃
      </div>
    </div>
  );
};

export default UserInformationPage;
