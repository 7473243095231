import React, { useEffect, useState } from "react";
import SizedBox from "./SizedBox";
import {
  faceToEmoji,
  planToEmoji,
  planToText,
  face,
  jobToEmoji,
  job,
  body,
  idelTypeToEmoji,
  idelType,
} from "../constant/informationKeyData.js";
import { db } from "../firebase.config";
import { updateDoc, doc, serverTimestamp } from "firebase/firestore";
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#FAF3E0",
    borderRadius: "20px",
    color: "#000",
    fontFamily: "Arial, sans-serif",
    width: "calc(100vw - 40px)",
    maxWidth: "353px",
    // height: "301px",
    boxSizing: "border-box",
    marginBottom: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  title: {
    color: "#D26760",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
  },
  profileInfo: {
    color: "black",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
    lineHeight: 1.7,
  },
  footer: {
    textAlign: "right",
    color: "black",
    fontSize: 10,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
  },
  jobInfo: {
    color: "black",
    fontSize: 16,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    lineHeight: 1.9,
  },
  buttonRow: {
    display: "flex",
    gap: "13px",
    alignItems: "center",
  },
  rejectButton: {
    width: "40%",
    padding: "10px",
    borderRadius: "40px",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    border: "1px #D26760 solid",
    backgroundColor: "transparent",
    color: "#D26760",
    cursor: "pointer",
  },
  finishRejectButton: {
    width: "100%",
    padding: "10px",
    borderRadius: "40px",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    border: "1px #D26760 solid",
    backgroundColor: "transparent",
    color: "#D26760",
  },
  acceptButton: {
    width: "100%",
    padding: "10px 10px",
    borderRadius: "40px",
    border: "none",
    backgroundColor: "#D26760",
    color: "white",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  finishAcceptButton: {
    width: "100%",
    padding: "10px 10px",
    borderRadius: "40px",
    border: "none",
    backgroundColor: "#D26760",
    color: "white",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emoji: {
    marginRight: "5px",
  },
};

const MatchesComponent = ({ matchData, targetTime, myUserData }) => {
  const [timeRemaining, setTimeRemaining] = useState("");
  const [isAllowed, setIsAllowed] = useState(matchData.isAllowed);
  const userMatchDataDoc = doc(
    db,
    `matches/${myUserData.id}/matches`,
    matchData.docId
  );

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const distance = targetTime.getTime() - now.getTime();

      if (distance > 0) {
        // 전체 시간(시간)을 계산합니다.
        const totalHours = Math.floor(distance / (1000 * 60 * 60));
        const minutes = String(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        ).padStart(2, "0");
        const seconds = String(
          Math.floor((distance % (1000 * 60)) / 1000)
        ).padStart(2, "0");
        setTimeRemaining(`${totalHours}:${minutes}:${seconds}`);
      } else {
        setTimeRemaining("00:00:00");
      }
    };
    calculateTimeRemaining();
    const interval = setInterval(calculateTimeRemaining, 1000);
    return () => clearInterval(interval);
  }, [targetTime]);

  const acceptFunction = async () => {
    await updateDoc(userMatchDataDoc, {
      isAllowed: true,
      isAllowedAt: serverTimestamp(),
    });
    setIsAllowed(true);
  };

  const deniedFunction = async () => {
    await updateDoc(userMatchDataDoc, {
      isAllowed: false,
      isAllowedAt: serverTimestamp(),
    });
    setIsAllowed(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>오늘의 인연</div>
        <div style={styles.footer}>🕒{timeRemaining}</div>
      </div>
      <div style={styles.jobInfo}>
        {/* {matchData.job} {matchData.age} */}
        {jobToEmoji[matchData.job]} {job[matchData.job]}
        {" " + matchData.age}살
      </div>
      <div style={styles.profileInfo}>
        {planToEmoji[matchData.plan]} 연말계획은 {planToText[matchData.plan]}
      </div>
      <div style={styles.profileInfo}>🧍 키는 {matchData.height}cm</div>
      <div style={styles.profileInfo}>
        {faceToEmoji[matchData.face]} 얼굴은 {face[matchData.face]}상
      </div>
      <div style={styles.profileInfo}>🧘 체형은 {body[matchData.body]}</div>
      <div style={styles.profileInfo}>🏠 거주 지역은 {matchData.address}</div>
      <div style={styles.profileInfo}>
        {idelTypeToEmoji[matchData.idealType]} 이상형은{" "}
        {idelType[matchData.idealType]}상
      </div>
      <div style={styles.profileInfo}>💬 자기소개: {matchData.introduce}</div>
      <SizedBox height={28} />
      {isAllowed == null || isAllowed === undefined ? (
        <div style={styles.buttonRow}>
          <button style={styles.rejectButton} onClick={deniedFunction}>
            거절하기
          </button>
          <button style={styles.acceptButton} onClick={acceptFunction}>
            <span role="img" aria-label="check" style={styles.emoji}>
              ✅
            </span>
            매칭 수락하기
          </button>
        </div>
      ) : isAllowed ? (
        <>
          <button style={styles.finishAcceptButton} disabled={true}>
            <span role="img" aria-label="check" style={styles.emoji}>
              ✅
            </span>
            매칭 수락 완료
          </button>
        </>
      ) : (
        <>
          <button
            style={styles.finishRejectButton}
            onClick={deniedFunction}
            disabled={true}
          >
            🥲 매칭 거절 완료
          </button>
        </>
      )}
    </div>
  );
};

export default MatchesComponent;
