import React from "react";

const ProgressBarWidget = ({ percent, currentStep }) => {
  // Main background style for the progress bar
  const backgroundStyles = {
    width: "248px",
    height: "20px",
    background: "rgba(255, 255, 255, 0.10)",
    borderRadius: 40,
    overflow: "hidden",
    position: "relative",
  };

  const textStyle = {
    textAlign: "right",
    color: "rgba(255, 255, 255, 0.40)",
    fontSize: 12,
    fontFamily: "LINE Seed Sans KR",
    fontWeight: "400",
    wordWrap: "break-word",
    marginBottom: "3px",
    marginRight: "10px",
  };
  // Dynamic style for the white progress bar
  const progressStyles = {
    width: `${percent}%`, // The width will change according to the percent
    height: "20px",
    background: "white",
    borderRadius: 40,
    transition: "width 0.5s ease-in-out", // Adds animation to the width change
  };
  const container = {
    display: "flex",
    flexDirection: "column",
    width: "248px",
  };

  return (
    <div style={container}>
      <div style={textStyle}>{currentStep}/10</div>
      <div style={backgroundStyles}>
        <div style={progressStyles} />
      </div>
    </div>
  );
};

export default ProgressBarWidget;
