import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Profile } from "../assets/profile.svg";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  // updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebase.config";
import NotInsertUserInformation from "../components/NotInsertUserInformation.jsx";
import CountdownTimer from "../components/CountDownTimer.jsx";
import MatchesComponent from "../components/MatchesComponent.jsx";
import MatchesComponentBeforePayment from "../components/MatchesComponentBeforePayment.jsx";
import MatchesSuccess from "../components/MatchesSuccess.jsx";
import { Indicator } from "../components/DefaultIndicator.jsx";
import { useAuth } from "../context/AuthContext.jsx";
import SizedBox from "../components/SizedBox.jsx";
import DefaultFooter from "../components/DefaultFooter.jsx";
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: "20px",
    backgroundColor: "#DB8580",
    minHeight: "100dvh", // 디바이스 뷰포트 높이로 설정
    color: "#fff",
    fontFamily: "Arial, sans-serif",
    paddingTop: "76px", // Add padding to avoid content hiding behind the fixed header
  },
  mainIconStyle: {
    marginBottom: "47px",
    marginTop: "23px",
    color: "white",
    fontSize: 54,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    marginBottom: "20px",
    position: "fixed",
    backgroundColor: "#DB8580", // Match container color for seamless integration
    top: 0,
    padding: "20px",
  },
  logoSection: {
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontFamily: "LINE Seed Sans KR",
    fontWeight: "700",
  },

  icon: {
    paddingRight: "20px",
    fontSize: "24px",
    alignItems: "center",
    display: "flex",
  },

  footerText: {
    width: "calc(100vw - 40px)",
    color: "white",
    fontSize: 12,
    fontFamily: "Noto Sans KR",
    fontWeight: "400",
    textAlign: "left",
    lineHeight: "1.7",
  },
  footerIcon: {
    marginRight: "5px",
  },
  stopText: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    color: "white",
    fontSize: 12,
    fontFamily: "LINE Seed Sans KR",
    fontWeight: "400",
    textDecoration: "underline",
    wordWrap: "break-word",
    marginTop: "202px",
    cursor: "pointer",
  },
};

const HomePage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);
  const [myUserDataState, setMyUserDataState] = useState({});
  const [targetDateState, setTargetDateState] = useState("");
  const [afterTwentyTwoState, setAfterTwentyTwoState] = useState("");
  const [matchesUserDataForSelect, setMatchesUserDataForSelect] = useState([]);
  const [matchesResultState, setMatchesResultState] = useState([]);
  const [isMatchingType, setIsMatchingType] = useState("");
  const [isNotSuccess, setIsNotSuccess] = useState(false);
  const now = new Date();
  const { user } = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      if (user) {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uid", "==", user.uid));

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty && querySnapshot.docs[0].data().age != null) {
          const userData = querySnapshot.docs[0].data();

          setMyUserDataState(userData);
          const targetDateDoc = (
            await getDoc(doc(db, "matches", "matchingTime"))
          ).data();

          const targetDate = targetDateDoc.targetTime.toDate();
          const lastDate = targetDateDoc.lastTargetTime.toDate();
          setTargetDateState(targetDate);

          const afterTwentyTwo = new Date(
            targetDate.getFullYear(),
            targetDate.getMonth(),
            targetDate.getDate() + 1,
            21
          );

          const afterFourtySix = new Date(
            targetDate.getFullYear(),
            targetDate.getMonth(),
            targetDate.getDate() + 2,
            21
          );
          const matchesDataArr = [];
          var matchedResult;

          const beforeFourDaysTimestamp = Timestamp.fromDate(
            new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate() - 4,
              lastDate.getHours()
            )
          );
          const matchesCollection = collection(
            db,
            `matches/${userData.id}/matches`
          );

          if (afterFourtySix <= now) {
            matchedResult = await getDocs(
              query(
                matchesCollection,
                where("createdAt", ">", targetDateDoc.lastTargetTime),
                where("createdAt", "<", targetDateDoc.targetTime),
                where("isPayment", "==", true)
              )
            );
          } else {
            matchedResult = await getDocs(
              query(
                matchesCollection,
                where("createdAt", ">", beforeFourDaysTimestamp),
                where("createdAt", "<", targetDateDoc.lastTargetTime),
                where("isPayment", "==", true)
              )
            );
            const matchesSnapshot = await getDocs(
              query(
                matchesCollection,
                where("createdAt", ">", targetDateDoc.lastTargetTime),
                where("createdAt", "<", targetDateDoc.targetTime)
              )
            );
            for (const d of matchesSnapshot.docs) {
              const matcheUserData = (
                await getDoc(doc(db, `users/${d.data().id}`))
              ).data();
              matcheUserData.isAllowed = d.data().isAllowed;
              matcheUserData.isSuccess = d.data().isSuccess;
              matcheUserData.isPayment = d.data().isPayment;
              matcheUserData.docId = d.id;
              matchesDataArr.push(matcheUserData);
              if (matcheUserData.isSuccess === false) {
                setIsNotSuccess(true);
              }
            }
          }
          const matchesResultArr = [];
          for (const d of matchedResult.docs) {
            const matcheUserData = (
              await getDoc(doc(db, `users/${d.data().id}`))
            ).data();
            matcheUserData.isAllowed = d.data().isAllowed;
            matcheUserData.isSuccess = d.data().isSuccess;
            matcheUserData.isPayment = d.data().isPayment;
            matcheUserData.docId = d.id;
            matchesResultArr.push(matcheUserData);
            if (matcheUserData.isSuccess === false) {
              setIsNotSuccess(true);
            }
          }
          setMatchesResultState(matchesResultArr);
          setAfterTwentyTwoState(afterTwentyTwo);
          setMatchesUserDataForSelect(matchesDataArr);
          if (now < afterTwentyTwo) {
            setIsMatchingType("matching");
            // setIsMatchingType("matchingResult");
          } else {
            setIsMatchingType("matchingResult");
          }

          setUserExists(true);
        } else {
          setUserExists(false);
        }
      }
      setIsLoading(false);
    };
    fetchUserData();
  }, []);

  // const onClickStop = async () => {
  //   const usersRef = collection(db, "users");
  //   const q = query(usersRef, where("uid", "==", user.uid));
  //   const querySnapshot = await getDocs(q);

  //   if (!querySnapshot.empty) {
  //     const userData = querySnapshot.docs[0].data();
  //     await updateDoc(doc(db, "users", userData.id), {
  //       isStop: true,
  //     });
  //   }
  // };
  const goToUserInformation = () => {
    navigate("/userInformation");
  };
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.logoSection}>
          <img src={logo} alt="Logo" width={32} height={32} />
          <div style={{ marginLeft: "8px" }}>연말계획</div>
        </div>
        <div style={styles.icon}>
          <Profile
            style={{ cursor: "pointer" }}
            onClick={() => {
              goToUserInformation();
            }}
          />
        </div>
      </div>
      {isLoading ? (
        <Indicator />
      ) : userExists ? (
        matchesUserDataForSelect.length > 0 || matchesResultState.length > 0 ? (
          isMatchingType === "matching" ? (
            <>
              {matchesUserDataForSelect.map((matchData, index) => (
                <MatchesComponent
                  key={index}
                  matchData={matchData}
                  targetTime={afterTwentyTwoState}
                  myUserData={myUserDataState}
                />
              ))}
              {matchesResultState.map((matchData, index) => (
                <MatchesSuccess matchData={matchData} key={index} />
              ))}
              <div style={styles.footerText}>
                👉 상대방과 내가 둘 다 매칭 수락을 하면 매칭이 성사돼요! 이후 두
                분의 연결을 도와드리기 위해 카카오톡 ID 전달이 이루어져요.
                <br />
                👉 이틀에 한번 밤 11시에 새로운 인연을 연결해드려요!
              </div>
              {/* <div style={styles.stopText} onClick={() => {}}>
                그만할게요
              </div> */}
            </>
          ) : (
            <>
              {matchesUserDataForSelect.map((matchData, index) => (
                <MatchesComponentBeforePayment
                  key={index}
                  matchData={matchData}
                  targetTime={afterTwentyTwoState}
                  myUserData={myUserDataState}
                />
              ))}
              {matchesResultState.map((matchData, index) => (
                <MatchesSuccess matchData={matchData} key={index} />
              ))}

              {!isNotSuccess ? (
                <div style={styles.footerText}>
                  👉 상대방과 내가 둘 다 매칭 수락을 하면 매칭이 성사돼요! 이후
                  두 분의 연결을 도와드리기 위해 카카오톡 ID 전달이 이루어져요.
                  <br />
                  👉 이틀에 한번 밤 11시에 새로운 인연을 연결해드려요!
                </div>
              ) : (
                <> </>
              )}
              {/* <div
                style={styles.stopText}
                onClick={() => {
                  onClickStop();
                }}
              >
                그만할게요
              </div> */}
            </>
          )
        ) : (
          <>
            <CountdownTimer targetTime={targetDateState} />
            <SizedBox height={20} />
            <div style={styles.footerText}>
              👉 상대방과 내가 둘 다 매칭 수락을 하면 매칭이 성사돼요! 이후 두
              분의 연결을 도와드리기 위해 카카오톡 ID 전달이 이루어져요.
              <br />
              👉 이틀에 한번 밤 11시에 새로운 인연을 연결해드려요!
            </div>
          </>
        )
      ) : (
        <>
          <NotInsertUserInformation />
          {/* <div style={styles.footerText}>
            👉 이미 프로필 입력을 완료하셨다면 잠시만 기다려주세요!
            <br />
            매일 오후 4시에 입력된 프로필이 매칭에 반영돼요.
          </div> */}
        </>
      )}

      <SizedBox height={32} />
      <DefaultFooter />
    </div>
  );
};

export default HomePage;
