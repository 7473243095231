import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../assets/left_arrow.svg";

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    color: "white",
    fontSize: 16,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    paddingRight: "20px",
  },
};

const DefaultHeader = ({ title, onPress }) => {
  const navigate = useNavigate();
  return (
    <div style={styles.header}>
      <LeftArrow
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (onPress != null) {
            onPress();
          } else {
            navigate("/");
          }
        }}
      />

      <div style={styles.title}>{title}</div>
      <div></div>
    </div>
  );
};

export default DefaultHeader;
