import React from "react";
import SizedBox from "./SizedBox";

const styles = {
  container: {
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F6EAD8",
    borderRadius: "20px",
    width: "calc(100vw - 40px)",
    maxWidth: "353px",
    // height: "305px",
    color: "#000",
    fontFamily: "Arial, sans-serif",
    boxSizing: "border-box",
    textAlign: "center",
    alignItems: "center",
  },

  title: {
    alignSelf: "flex-start",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#D26760",
    textAlign: "left",
    marginTop: "16px",
    marginLeft: "20px",
  },

  finishAcceptButton: {
    // width: `calc(100% - 170px)`,
    padding: "10px 33px 10px 34px",
    borderRadius: "40px",
    border: "none",
    backgroundColor: "rgba(210, 103, 96, 0.40)",
    color: "black",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
    // display: "flex",
  },
  icon: {
    marginBottom: "23px",
    marginTop: "47px",
    color: "white",
    fontSize: 54,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
  },
};

const MatchesSuccess = ({ matchData }) => {
  return (
    <div style={styles.container}>
      <div style={styles.title}>어젯밤 인연✨</div>
      <div style={styles.icon}>🎉</div>
      <>
        <div
          style={{
            textAlign: "center",
            color: "black",
            fontSize: 14,
            fontFamily: "Noto Sans KR",
            fontWeight: "500",
            wordWrap: "break-word",
            marginBottom: 20,
          }}
        >
          이틀 전 매칭이 최종 성사되었어요 🎉 
          <br />
          설레는 만남을 시작해보세요!
        </div>
        <div style={styles.finishAcceptButton}>{matchData.id}</div>
        <SizedBox height={40} />
      </>
    </div>
  );
};

export default MatchesSuccess;
