import React from "react";

import "../constant/informationKeyData.js";
import {
  faceToEmoji,
  planToEmoji,
  planToText,
  face,
  jobToEmoji,
  job,
  body,
  idelTypeToEmoji,
  idelType,
} from "../constant/informationKeyData.js";
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#F6EAD8",
    borderRadius: "20px",
    color: "#000",
    fontFamily: "Arial, sans-serif",
    width: "calc(100vw - 40px)",
    maxWidth: "353px",
    // height: "301px",
    boxSizing: "border-box",
    marginBottom: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  title: {
    color: "#D26760",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
  },
  profileInfo: {
    color: "black",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
    lineHeight: 1.7,
  },
  footer: {
    textAlign: "right",
    color: "rgba(0, 0, 0, 0.70)",
    fontSize: 12,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
  },
  jobInfo: {
    color: "black",
    fontSize: 16,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    lineHeight: 1.9,
  },
};

const ProfileCard = ({ userData }) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>내 프로필</div>
        <div style={styles.footer}>{userData.id}</div>
      </div>
      <div style={styles.jobInfo}>
        {jobToEmoji[userData.job]} {job[userData.job]}
        {" " + userData.age}살
      </div>
      <div style={styles.profileInfo}>
        {planToEmoji[userData.plan]} 연말계획은 {planToText[userData.plan]}
        {}
      </div>
      <div style={styles.profileInfo}>🧍 키는 {userData.height}cm</div>
      <div style={styles.profileInfo}>
        {faceToEmoji[userData.face]} 얼굴은 {face[userData.face]}상
      </div>
      <div style={styles.profileInfo}>🧘 체형은 {body[userData.body]}</div>
      <div style={styles.profileInfo}>🏠 거주 지역은 {userData.address}</div>
      <div style={styles.profileInfo}>
        {idelTypeToEmoji[userData.idealType]} 이상형은{" "}
        {idelType[userData.idealType]}상
      </div>
      <div style={styles.profileInfo}>💬 자기소개: {userData.introduce}</div>
    </div>
  );
};

export default ProfileCard;
