// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { onAuthStateChanged } from "firebase/auth";
// import "./App.css";
// import WelcomePage from "./pages/WelcomePage.jsx";
// import JoinPage from "./pages/Join.jsx";
// import HomePage from "./pages/Home.jsx";
// import LoginPage from "./pages/LoginPage.jsx";
// import UserInformationPage from "./pages/UserInformation.jsx";
// import { useState, useEffect } from "react";
// import { auth } from "./firebase.config";
// import InsertMyInformation from "./pages/InsertMyInformation.jsx";

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setIsAuthenticated(!!user); // user가 존재하면 로그인 상태로 설정
//     });
//     return () => unsubscribe(); // 컴포넌트 언마운트 시 구독 해제
//   });

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             isAuthenticated ? <Navigate to="/home" replace /> : <WelcomePage />
//           }
//         />
//         <Route
//           path="/home"
//           element={isAuthenticated ? <HomePage /> : <Navigate to="/" replace />}
//         />
//         <Route
//           path="/join"
//           element={
//             !isAuthenticated ? <JoinPage /> : <Navigate to="/" replace />
//           }
//         />
//         <Route
//           path="/userInformation"
//           element={
//             isAuthenticated ? (
//               <UserInformationPage />
//             ) : (
//               <Navigate to="/" replace />
//             )
//           }
//         />
//         <Route path="/login" element={<LoginPage />} />
//         <Route
//           path="/insert-my-information"
//           element={<InsertMyInformation />}
//         />
//       </Routes>
//     </Router>
//   );
// }
// export default App;

// App.js
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import WelcomePage from "./pages/WelcomePage.jsx";
import JoinPage from "./pages/Join.jsx";
import HomePage from "./pages/Home.jsx";
import LoginPage from "./pages/LoginPage.jsx";
import PaymentSuccess from "./pages/PaymentSuccess.jsx";
import UserInformationPage from "./pages/UserInformation.jsx";
import InsertMyInformation from "./pages/InsertMyInformation.jsx";
import { useAuth } from "./context/AuthContext.jsx";
import { GradientCircularProgressFromBackground } from "./components/DefaultIndicator.jsx";

function App() {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated === null) {
    return <GradientCircularProgressFromBackground />; // Show a loading indicator or screen
  }
  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? <Navigate to="/home" replace /> : <WelcomePage />
        }
      />
      <Route
        path="/home"
        element={isAuthenticated ? <HomePage /> : <Navigate to="/" replace />}
      />
      <Route path="/join" element={<JoinPage />} />
      <Route
        path="/userInformation"
        element={
          isAuthenticated ? (
            <UserInformationPage />
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/insert-my-information"
        element={
          isAuthenticated ? (
            <InsertMyInformation />
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      <Route path="/payment-success" element={<PaymentSuccess />} />
    </Routes>
  );
}

export default App;
