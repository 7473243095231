function SkinColorBox({ children = [] }) {
  return (
    <div
      style={{
        width: "calc(100vw - 40px)",
        maxWidth: 353,
        left: 20,
        top: 358,
        background: "#FAF3E0",
        borderRadius: 20,
        backdropFilter: "blur(4px)",
      }}
    >
      {children}
    </div>
  );
}
export default SkinColorBox;
