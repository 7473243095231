const PaymentSuccess = () => {
  const styles = {
    body: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      margin: 0,
      backgroundColor: "#db8580",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      margin: 0,
    },
    imageContainer: {
      width: "272.01px",
      height: "229px",
      borderRadius: "10px",
      background:
        "url(https://firebasestorage.googleapis.com/v0/b/nocap-fa72f.appspot.com/o/marketing_image_2.png?alt=media&token=b6bc85d1-ecc0-47f3-a371-d8017cb56497) no-repeat center",
      backgroundSize: "cover",
    },
    messageBox: {
      width: "353px",
      height: "144px",
      flexShrink: 0,
      borderRadius: "20px",
      backgroundColor: "#faf3e0",
      padding: "0 20px",
    },
    messageTitle: {
      height: "30px",
      color: "#d26760",
      fontSize: "18px",
      fontWeight: "700",
    },
    messageDescription: {
      height: "48px",
      color: "#000",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: "700",
    },
    backButton: {
      height: "56px",
      width: "calc(100% - 80px)",
      maxWidth: "313px",
      borderRadius: "40px",
      background: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px",
      fontWeight: "700",
      color: "#000",
      border: "none",
      cursor: "pointer",
    },
  };

  return (
    <body style={styles.body}>
      <div style={styles.container}>
        <div style={styles.container}>
          <div style={{ height: "126px" }}></div>
          <div style={styles.imageContainer}></div>
          <div style={{ height: "22px" }}></div>
          <div style={styles.messageBox}>
            <div style={{ height: "20px" }}></div>
            <div style={styles.messageTitle}>결제 완료</div>
            <div style={{ height: "4px" }}></div>
            <div style={styles.messageDescription}>
              결제 확인 후 최대 24시간 내에
              <br />
              상대의 연락처를 카톡으로 전달드려요.
              <br />
              좋은 인연이 되시길 응원해요 😀
            </div>
          </div>
          <div style={{ height: "40px" }}></div>
          <button
            onClick={() => (window.location.href = "https://ourplan.co.kr")}
            style={styles.backButton}
          >
            돌아가기
          </button>
        </div>
      </div>
    </body>
  );
};

export default PaymentSuccess;
