import React from "react";
import { useState } from "react";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Cross } from "../assets/cross.svg";
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    paddingTop: "20px",
    backgroundColor: "#DB8580",
    maxWidth: "100%",
    height: "100vh",
    color: "#fff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    marginBottom: "59px",
  },
  title: {
    textAlign: "center",
    color: "white",
    fontSize: 16,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    paddingRight: "20px",
  },
  subtitle: {
    marginBottom: "20px",
    fontSize: 18,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    marginBottom: "16px",
    width: "100%",
    maxWidth: "329px",
    background: "rgba(255, 255, 255, 0.40)",
    borderRadius: 20,
  },
  input: {
    flex: 1,
    border: "none",
    backgroundColor: "transparent",
    color: "#fff",
    padding: "8px",
    fontSize: "14px",
  },
  button: {
    marginLeft: "10px",
    padding: "12px 27px 13px",
    backgroundColor: "#fff",

    textAlign: "center",
    color: "black",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",

    cursor: "pointer",
    background: "white",
    borderRadius: 40,
    border: "1px #D26760 solid",
  },
  loginButton: {
    width: "100%",
    maxWidth: "313px",
    padding: "12px",
    background: "white",
    borderRadius: 40,
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer",
    border: "none",
    marginTop: "16px",
  },
  footerText: {
    textAlign: "center",
    color: "rgba(255, 255, 255, 0.80)",
    fontSize: 12,
    fontFamily: "Noto Sans KR",
    fontWeight: "400",
    textDecoration: "underline",
    wordWrap: "break-word",
    marginTop: "60px",
  },
};

const LoginScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sendVertification, setSendVertification] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [authCode, setAuthCode] = useState("");

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleAuthCodeChange = (event) => {
    setAuthCode(event.target.value);
  };
  const onCaptchVerify = async (event) => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "send_verification_code_button",
      {
        size: "invisible",
        callback: () => {
          console.log("recaptcha resolved..");
        },
      }
    );
  };
  function onSignIn() {
    if (!phoneNumber || phoneNumber.length < 10) {
      alert("핸드폰 번호를 입력해주세요.");
      return;
    }
    setSendVertification(true);
    setLoading(true);
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+82${phoneNumber}`, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function onConfirm() {
    setLoading(true);
    window.confirmationResult
      .confirm(authCode)
      .then((result) => {
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        alert("인증번호가 올바르지 않습니다.");
        setLoading(false);
      });
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <Cross
          style={{ cursor: "pointer", marginLeft: "20px" }}
          onClick={() => {
            navigate("/");
          }}
        />
        <div style={styles.title}>로그인</div>
        <div></div>
      </div>
      <div style={styles.subtitle}>연말계획에 로그인하세요</div>
      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          placeholder="핸드폰번호"
          value={phoneNumber}
          onChange={handlePhoneChange}
          disabled={sendVertification}
          type="number"
        />
        <button
          style={styles.button}
          id="send_verification_code_button"
          disabled={loading}
          onClick={() => {
            onSignIn();
          }}
        >
          인증
        </button>
      </div>
      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          placeholder="인증번호"
          onChange={handleAuthCodeChange}
          value={authCode}
          type="number"
        />
      </div>
      <button
        style={styles.loginButton}
        onClick={() => {
          onConfirm();
        }}
      >
        로그인
      </button>
      {/* <div style={styles.footerText}>
        비밀번호 분실 시 our@justpin.io로 문의 부탁드립니다.
      </div> */}
    </div>
  );
};

export default LoginScreen;
