import React from "react";
import logo from "../assets/logo.png";
import SkinColorBox from "../components/SkinColorBox";
import SizedBox from "../components/SizedBox";
import { useNavigate } from "react-router-dom";

function WelcomePage() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "#DB8580",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center-aligns all elements horizontally
        padding: "20px 0", // Optional padding for spacing
      }}
    >
      <SizedBox height={104} />
      <img src={logo} alt="Logo" width={66} height={66} />
      <SizedBox height={48} />
      <div
        style={{
          textAlign: "center",
          color: "white",
          fontSize: 26,
          fontFamily: "LINE Seed Sans KR",
          fontWeight: "700",
          marginBottom: 10,
        }}
      >
        🗓️ 연말계획 🗓️
      </div>

      <div
        style={{
          textAlign: "center",
          color: "white",
          fontSize: 16,
          fontFamily: "LINE Seed Sans KR",
          fontWeight: "700",
        }}
      >
        혼자 보내기 싫은 연말,
        <br />
        취향 맞는 인연과 함께 보내기
      </div>
      <div style={{ height: 52 }}></div>
      <SkinColorBox
        children={
          <div style={{ textAlign: "center" }}>
            <div style={{ height: 32 }} />
            <div
              style={{
                color: "#D26760",
                fontSize: 25,
                fontFamily: "LINE Seed Sans KR",
                fontWeight: "700",
                marginBottom: 20,
              }}
            >
              🎄연말을 위한 20대 커플 매칭
            </div>
            <div
              style={{
                color: "black",
                fontSize: 14,
                fontFamily: "LINE Seed Sans KR",
                textAlign: "center",
                fontWeight: "700",
                marginBottom: 32,
              }}
            >
              ❌ 귀찮은 앱 다운도, 과한 과금도 없어요
              <br />✅ 취향이 통하는 인연을 매칭해드려요
            </div>
            <button
              style={{
                width: `calc(100% - 133px)`,
                height: 52,
                background: "#D26760",
                borderRadius: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("join");
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Noto Sans KR",
                  fontWeight: "700",
                  textAlign: "center",
                  paddingBottom: 1,
                }}
              >
                1분만에 가입하기
              </div>
            </button>
            <SizedBox height={24} />
          </div>
        }
      />
      <SizedBox height={16} />
      <div style={{ width: "100%", textAlign: "center" }}>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 14,
            fontFamily: "Noto Sans KR",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          이미 계정이 있다면?{" "}
        </span>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 14,
            fontFamily: "Noto Sans KR",
            fontWeight: "400",
            textDecoration: "underline",
            wordWrap: "break-word",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("login");
          }}
        >
          로그인하기
        </span>
      </div>
      <SizedBox height={157} />
      <div
        style={{
          color: "white",
          fontSize: 14,
          fontFamily: "LINE Seed Sans KR",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        by 서울대 경영대 창업팀
      </div>
    </div>
  );
}

export default WelcomePage;
