import React, { useEffect, useState } from "react";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F6EAD8",
    borderRadius: "20px",
    width: "calc(100vw - 40px)",
    maxWidth: "353px",
    height: "305px",
    color: "#000",
    fontFamily: "Arial, sans-serif",
    boxSizing: "border-box",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#D26760",
    textAlign: "left",
    marginTop: "16px",
    marginLeft: "20px",
  },
  icon: {
    marginBottom: "32px",
    marginTop: "59px",
    color: "white",
    fontSize: 54,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
  },
  mainText: {
    fontSize: "16px",
    marginBottom: "8px",
    fontWeight: "500",
    wordWrap: "break-word",
  },
  timer: {
    fontSize: "16px",
    color: "#D97C6A",
    fontWeight: "700",
    wordWrap: "break-word",
  },
};

const CountdownTimer = ({ targetTime }) => {
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();

      const targetDataTime = new Date(
        targetTime.getFullYear(),
        targetTime.getMonth(),
        targetTime.getDate(),
        targetTime.getHours() + 48
      );

      const distance = targetDataTime.getTime() - now.getTime();

      if (distance > 0) {
        // 전체 시간(시간)을 계산합니다.
        const totalHours = Math.floor(distance / (1000 * 60 * 60));
        const minutes = String(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        ).padStart(2, "0");
        const seconds = String(
          Math.floor((distance % (1000 * 60)) / 1000)
        ).padStart(2, "0");
        setTimeRemaining(`${totalHours}:${minutes}:${seconds}`);
      } else {
        setTimeRemaining("00:00:00");
      }
    };
    calculateTimeRemaining();
    const interval = setInterval(calculateTimeRemaining, 1000);
    return () => clearInterval(interval);
  }, [targetTime]);

  return (
    <div style={styles.container}>
      <div style={styles.subtitle}>오늘의 인연 ✨</div>
      <div style={styles.icon}>🔍</div>
      <div style={styles.mainText}>새로운 인연을 만나기까지</div>
      <div style={styles.timer}>{timeRemaining} 남았어요</div>
    </div>
  );
};

export default CountdownTimer;
