import React from "react";
import { useNavigate } from "react-router-dom";
const styles = {
  mainIconStyle: {
    marginBottom: "47px",
    marginTop: "23px",
    color: "white",
    fontSize: 54,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
  },
  contentBox: {
    width: "calc(100vw - 40px)",
    backgroundColor: "#F6EAD8",
    borderRadius: "20px",
    paddingTop: "16px",
    paddingBottom: "40px",
    // paddingLeft: "20px",
    // paddingRight: "20px",
    textAlign: "center",
    maxWidth: "353px",
    marginBottom: "20px",
  },
  subtitle: {
    paddingLeft: "20px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#D26760",
    textAlign: "left",
  },
  mainText: {
    color: "black",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
    paddingBottom: "20px",
  },
  button: {
    padding: "10px 30px 11px",
    backgroundColor: "#D26760",
    color: "white",
    borderRadius: "40px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    border: "none",
  },
};
const NotInsertUserInformation = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // window.open("https://smore.im/form/xlXPifOJTI", "_blank");
    navigate("/insert-my-information");
  };
  return (
    <div style={styles.contentBox}>
      <div style={styles.subtitle}>오늘의 인연 ✨</div>
      <div style={styles.mainIconStyle}> 💫</div>
      <div style={styles.mainText}>
        매일 밤 11시, 새로운 인연을 만나고 싶다면?
      </div>
      <button
        style={styles.button}
        onClick={() => {
          handleButtonClick();
        }}
      >
        간단 프로필 입력하기
      </button>
    </div>
  );
};

export default NotInsertUserInformation;
