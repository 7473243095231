import React, { useState } from "react";
import DefaultHeader from "../components/DefaultHeader.jsx";
import SizedBox from "../components/SizedBox.jsx";
import logo from "../assets/logo.png";
import "../styles/default_input_style.css";
import ProgressBarWidget from "../components/insert_user_information/ProgressBar.jsx";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase.config";
import "../styles/transitions.css"; // Import your CSS for animations
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext.jsx";
import { GradientCircularProgressFromBackground } from "../components/DefaultIndicator.jsx";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#DB8580",
    height: "100vh",
    color: "#fff",
  },
  nextButton: {
    width: "200px",
    height: "56px",
    background: "white",
    border: "none",
    borderRadius: "40px",
    textAlign: "center",
    color: "black",
    fontSize: 18,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    width: "313px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.40)",
    color: "#FFF",
    fontFamily: "Noto Sans KR",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    borderRadius: "20px",
    padding: "10px",
    marginBottom: "10px",
  },
  inputContainerForIntroduce: {
    width: "313px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.40)",
    color: "#FFF",
    fontFamily: "Noto Sans KR",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    borderRadius: "20px",
    padding: "16px",
    marginBottom: "10px",
  },
  input: {
    flex: 1,
    border: "none",
    backgroundColor: "transparent",
    color: "#FFF",
    fontFamily: "Noto Sans KR",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    padding: "8px",
    resize: "none", // Prevents manual resizing handle
    overflow: "hidden", // Prevents scrollbar until needed
  },
  contentButtonStyle: {
    width: "313px",
    height: "56px",
    background: "white",
    borderRadius: 40,
    color: "black",
    fontSize: 16,
    fontFamily: "LINE Seed Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    cursor: "pointer",
  },
  explanationStyle: {
    width: "100%",
    textAlign: "center",
    color: "white",
    fontSize: 16,
    fontFamily: "LINE Seed Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    whiteSpace: "pre-line",
  },
};

const InsertMyInformation = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [plan, setPlan] = useState("");
  const [job, setJob] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [face, setFace] = useState("");
  const [body, setBody] = useState("");
  const [address, setAddress] = useState("");
  const [idealType, setIdealType] = useState("");
  const [introduce, setIntroduce] = useState("");

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const updateUserData = async () => {
    if (user) {
      setIsLoading(true);
      const updateData = {
        plan: plan,
        job: job,
        gender: gender,
        age: age,
        height: height,
        face: face,
        body: body,
        address: address,
        idealType: idealType,
        introduce: introduce,
        insertUserProfileAt: serverTimestamp(),
      };
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("uid", "==", user.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        await updateDoc(doc(db, "users", userData.id), updateData);
      }
      navigate("/");
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
      setProgress((prev) => prev + 10);
    }
  };
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      setProgress((prev) => prev - 10);
    } else {
      navigate("/");
    }
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const handleAgeChange = (e) => {
    setAge(e.target.value);
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const handleIntroduceChange = (e) => {
    setIntroduce(e.target.value);
  };
  const steps = [
    <StepPlan setProps={setPlan} handleNext={handleNext} />,
    <StepJob setProps={setJob} handleNext={handleNext} />,
    <StepAge onChange={handleAgeChange} props={age} handleNext={handleNext} />,
    <StepGender setProps={setGender} handleNext={handleNext} />,
    <StepHeight
      props={height}
      onChange={handleHeightChange}
      handleNext={handleNext}
    />,
    <StepFace setProps={setFace} handleNext={handleNext} />,
    <StepBody setProps={setBody} handleNext={handleNext} />,
    <StepAddress
      props={address}
      onChange={handleAddressChange}
      handleNext={handleNext}
    />,
    <StepIdealType setProps={setIdealType} handleNext={handleNext} />,
    <StepIntrouce
      props={introduce}
      onChange={handleIntroduceChange}
      updateUserData={updateUserData}
    />,
  ];

  if (isLoading) {
    return <GradientCircularProgressFromBackground />;
  }

  return (
    <div style={styles.container}>
      <DefaultHeader title="프로필 입력" onPress={handleBack} />
      <SizedBox height={32} />
      <img src={logo} alt="Logo" width={66} height={66} />
      <SizedBox height={4} />
      <ProgressBarWidget
        currentStep={currentStep + 1}
        percent={progress + 10}
      />
      <SizedBox height={12} />
      {steps[currentStep]}
    </div>
  );
};

const ExplanationText = ({ text }) => (
  <div style={styles.explanationStyle}>{text}</div>
);
const StepPlan = ({ setProps, handleNext }) => {
  const contentText = [
    "🚗 같이 드라이브/여행",
    "🍽️ 같이 맛집 가기",
    "🍻 같이 술 한 잔",
    "🎬 같이 영화 보기",
    "🎶 같이 공연/전시 보기",
    "🎡 같이 놀이공원",
  ];
  const keyList = ["drive", "restaurant", "drink", "movie", "amusementPark"];
  return (
    <div>
      <ExplanationText text={"함께하고 싶은 연말 계획을 골라주세요."} />
      <SizedBox height={80} />
      {contentText.map((text, index) => (
        <div
          style={styles.contentButtonStyle}
          key={index}
          onClick={() => {
            setProps(keyList[index]);
            handleNext();
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

const StepJob = ({ setProps, handleNext }) => {
  const contentText = [
    "🎓 대학생",
    "💼 직장인",
    "📚 대학원생",
    "🖥️ 프리랜서",
    "📑 취준생",
  ];

  const keyList = [
    "student",
    "officeWorker",
    "postgrad",
    "freelancer",
    "jobSeeker",
  ];

  return (
    <div>
      <ExplanationText text={"현재 직업을 알려주세요."} />
      <SizedBox height={80} />
      {contentText.map((text, index) => (
        <div
          style={styles.contentButtonStyle}
          key={index}
          onClick={() => {
            setProps(keyList[index]);
            handleNext();
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

const StepAge = ({ props, onChange, handleNext }) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleNextClick = () => {
    if (!props || props < 20 || props > 29) {
      setOpen(true);
    } else {
      handleNext();
    }
  };
  return (
    <>
      <ExplanationText
        text={"나이를 적어주세요.\n(*20대만 참여가 가능해요!)"}
      />
      <SizedBox height={29} />
      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          placeholder="숫자를 입력해주세요 (한국나이)"
          value={props}
          onChange={onChange}
          type="number"
        />
      </div>
      <SizedBox height={20} />
      <div
        style={styles.nextButton}
        onClick={() => {
          handleNextClick();
        }}
      >
        다음
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#D26760",
          }}
          message={"20대만 참여가 가능해요!"}
          action={
            <Button
              color="white"
              size="small"
              onClick={handleClose}
              style={{
                backgroundColor: "#DB8580",
              }}
            >
              닫기
            </Button>
          }
        />
      </Snackbar>
    </>
  );
};

const StepGender = ({ setProps, handleNext }) => {
  const contentText = { female: "🙎‍♀️ 여성이에요", male: "🙎‍♂️ 남성이에요" };

  return (
    <div>
      <ExplanationText text={"성별을 선택해주세요."} />
      <SizedBox height={80} />
      {Object.entries(contentText).map(([key, text]) => (
        <div
          style={styles.contentButtonStyle}
          key={key}
          onClick={() => {
            setProps(key);
            handleNext();
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

const StepHeight = ({ props, onChange, handleNext }) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleNextClick = () => {
    if (props.length === 0) {
      setOpen(true);
    } else {
      handleNext();
    }
  };
  return (
    <>
      <ExplanationText text={"키를 입력해주세요."} />
      <SizedBox height={29} />
      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          placeholder="숫자를 입력해주세요 (cm)"
          value={props}
          onChange={onChange}
          type="number"
          className="default_input_style"
        />
      </div>
      <SizedBox height={50} />
      <div
        style={styles.nextButton}
        onClick={() => {
          handleNextClick();
        }}
      >
        다음
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#D26760",
          }}
          message={"키를 입력해주세요!"}
          action={
            <Button
              color="white"
              size="small"
              onClick={handleClose}
              style={{
                backgroundColor: "#DB8580",
              }}
            >
              닫기
            </Button>
          }
        />
      </Snackbar>
    </>
  );
};
const StepFace = ({ setProps, handleNext }) => {
  const contentText = [
    "🐶 강아지",
    "🐱 고양이",
    "🐰 토끼",
    "🦊 여우",
    "🐴 말",
    "🦖 공룡",
  ];
  const keyList = ["dog", "cat", "rabbit", "fox", "horse", "dinosaur"];

  return (
    <div>
      <ExplanationText text={"나의 얼굴상은?"} />
      <SizedBox height={80} />
      {contentText.map((text, index) => (
        <div
          style={styles.contentButtonStyle}
          key={index}
          onClick={() => {
            setProps(keyList[index]);
            handleNext();
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};
const StepBody = ({ setProps, handleNext }) => {
  const contentText = [
    "마른 체형",
    "보통 체형",
    "슬림탄탄",
    "통통한 체형",
    "근육질",
  ];
  const keyList = ["thin", "normal", "slim", "chubby", "muscular"];

  return (
    <div>
      <ExplanationText text={"나의 체형은?"} />
      <SizedBox height={80} />
      {contentText.map((text, index) => (
        <div
          style={styles.contentButtonStyle}
          key={index}
          onClick={() => {
            setProps(keyList[index]);
            handleNext();
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};
const StepAddress = ({ props, onChange, handleNext }) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleNextClick = () => {
    if (props.length === 0) {
      setOpen(true);
    } else {
      handleNext();
    }
  };
  return (
    <>
      <ExplanationText
        text={"거주 지역을 입력해주세요\n(⚠️ 수도권 거주자만 참여가 가능해요!)"}
      />
      <SizedBox height={29} />
      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          placeholder="ex. 서울 관악구, 성남 분당구 등"
          value={props}
          onChange={onChange}
        />
      </div>
      <SizedBox height={20} />
      <div
        style={styles.nextButton}
        onClick={() => {
          handleNextClick();
        }}
      >
        다음
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#D26760",
          }}
          message={"거주 지역을 입력해주세요!"}
          action={
            <Button
              color="white"
              size="small"
              onClick={handleClose}
              style={{
                backgroundColor: "#DB8580",
              }}
            >
              닫기
            </Button>
          }
        />
      </Snackbar>
    </>
  );
};
const StepIdealType = ({ setProps, handleNext }) => {
  const contentText = [
    "🐶 강아지",
    "🐱 고양이",
    "🐰 토끼",
    "🦊 여우",
    "🐴 말",
    "🦖 공룡",
  ];

  const keyList = ["dog", "cat", "rabbit", "fox", "horse", "dinosaur"];

  return (
    <div>
      <ExplanationText text={"이상형의 얼굴상을 선택해주세요."} />
      <SizedBox height={80} />
      {contentText.map((text, index) => (
        <div
          style={styles.contentButtonStyle}
          key={index}
          onClick={() => {
            setProps(keyList[index]);
            handleNext();
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};
const StepIntrouce = ({ props, onChange, updateUserData }) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleNextClick = () => {
    if (props.length < 20) {
      setOpen(true);
    } else {
      updateUserData();
    }
  };
  return (
    <>
      <ExplanationText
        text={
          "마지막으로, 자기소개를 작성해주세요. \n(정성스레 써주시면 매칭 확률이 올라가요!)"
        }
      />
      <SizedBox height={29} />
      <div style={styles.inputContainerForIntroduce}>
        <textarea
          style={styles.input}
          placeholder="텍스트를 입력해주세요"
          value={props}
          onChange={onChange}
          rows={10}
          className="default_input_style"
        />
      </div>
      <SizedBox height={20} />
      <div
        style={styles.nextButton}
        onClick={() => {
          handleNextClick();
        }}
      >
        다음
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#D26760",
          }}
          message={"자기소개를 최소 20자 이상 작성해주세요!"}
          action={
            <Button
              color="white"
              size="small"
              onClick={handleClose}
              style={{
                backgroundColor: "#DB8580",
              }}
            >
              닫기
            </Button>
          }
        />
      </Snackbar>
    </>
  );
};

export default InsertMyInformation;
