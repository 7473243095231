import React from "react";
import { useState } from "react";
import "../styles/default_input_style.css";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "../firebase.config";
import SizedBox from "../components/SizedBox";
import { useNavigate } from "react-router-dom";
import { GradientCircularProgressFromBackground } from "../components/DefaultIndicator";
import { db } from "../firebase.config";
import { ReactComponent as Cross } from "../assets/cross.svg";
import {
  setDoc,
  doc,
  serverTimestamp,
  getDoc,
  updateDoc,
  where,
  collection,
  query,
  getDocs,
} from "firebase/firestore";
auth.languageCode = "ko";

const styles = {
  container: {
    width: "100%",
    paddingTop: "20px",
    // maxWidth: "393px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#DB8580",
    height: "100vh",
    // margin: "0 auto",
    color: "#fff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    marginBottom: "20px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    paddingRight: "22px",
  },
  section: {
    width: "100%",
    marginBottom: "20px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "8px",
  },
  inputContainer: {
    marginBottom: "10px",
    margin: "0 auto",
    width: "329px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.40)",
    color: "#FFF",
    fontFamily: "Noto Sans KR",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    borderRadius: "20px",
    padding: "10px",
  },
  input: {
    flex: 1,
    border: "none",
    backgroundColor: "transparent",
    color: "#FFF",
    fontFamily: "Noto Sans KR",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    padding: "8px",
  },

  button: {
    marginLeft: "10px",
    padding: "12px 27px 13px",
    backgroundColor: "#fff",
    color: "#000",
    textAlign: "center",
    fontFamily: "Noto Sans KR",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    cursor: "pointer",
    borderRadius: "40px",
    border: "1px solid #D26760",
    background: "#FFF",
  },
  agreement: {
    textAlign: "center",
    marginBottom: "20px",
    color: "white",
    fontSize: 12,
    fontFamily: "Noto Sans KR",
    fontWeight: "400",
    wordWrap: "break-word",
  },
  submitButton: {
    width: "313px",
    padding: "13px 0px 13px 0px",
    backgroundColor: "#fff",
    color: "black",
    fontSize: 18,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    borderRadius: "24px",
    textAlign: "center",
    cursor: "pointer",
    border: "none",
    display: "block", // Add this line
    margin: "0 auto", // Add this line
  },
  terms: {
    color: "white",
    fontSize: 12,
    fontFamily: "Noto Sans KR",
    fontWeight: "400",
    textDecoration: "underline",
    wordWrap: "break-word",
    cursor: "pointer",
  },
  warning: {
    width: "305px",
    margin: "0 auto",
    color: "white",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    marginTop: "32px",
    marginBottom: "12px",
  },
};

const MembershipForm = () => {
  const navigate = useNavigate();
  const [sendVertification, setSendVertification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siginUpLoading, setSignUpLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [id, setId] = useState("");

  const handleIdChange = (event) => {
    setId(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleAuthCodeChange = (event) => {
    setAuthCode(event.target.value);
  };

  const onCaptchVerify = async (event) => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "send_verification_code_button",
      {
        size: "invisible",
        callback: () => {
          console.log("recaptcha resolved..");
        },
      }
    );
  };

  async function checkPhoneNumber() {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("phoneNumber", "==", phoneNumber));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return true;
    }
    return false;
  }
  async function onSignup() {
    setSendVertification(true);
    setLoading(true);
    const havePhoneNumber = await checkPhoneNumber();

    if (havePhoneNumber) {
      alert("이미 가입된 번호입니다!");
      setSendVertification(false);
      setLoading(false);
      return;
    }

    if (!phoneNumber || phoneNumber.length < 10) {
      alert("핸드폰 번호를 입력해주세요!");
      setSendVertification(false);
      setLoading(false);
      return;
    }

    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+82${phoneNumber}`, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setSendVertification(false);
      });
  }

  function onConfirm() {
    if (phoneNumber.length < 10 || authCode.length < 6 || id.length < 1) {
      alert("핸드폰 인증과 카카오톡 ID 입력을 모두 완료해주세요!");
      return;
    }
    setSignUpLoading(true);
    setLoading(true);
    window.confirmationResult
      .confirm(authCode)
      .then(async (result) => {
        const uid = result.user.uid;
        const userDoc = await getDoc(doc(db, "users", id));
        if (userDoc.exists) {
          const userRef = doc(db, "users", id);
          await updateDoc(userRef, {
            uid: uid,
            phoneNumber: phoneNumber,
            createdAt: serverTimestamp(),
          });
        } else {
          const userRef = doc(db, "users", id);
          await setDoc(userRef, {
            uid: uid,
            phoneNumber: phoneNumber,
            id: id,
            createdAt: serverTimestamp(),
          });
        }

        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        alert("인증번호가 올바르지 않습니다.");
        setLoading(false);
      });
  }

  if (siginUpLoading) {
    return <GradientCircularProgressFromBackground />;
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <Cross
          style={{ cursor: "pointer", marginLeft: "20px" }}
          onClick={() => {
            navigate("/");
          }}
        />
        <div style={styles.title}>회원가입</div>
        <div></div>
      </div>

      <div style={styles.section}>
        <div style={styles.inputContainer}>
          <input
            style={styles.input}
            placeholder="핸드폰번호"
            value={phoneNumber}
            onChange={handlePhoneChange}
            disabled={sendVertification}
            type="number"
          />
          <button
            id="send_verification_code_button"
            style={styles.button}
            onClick={() => {
              onSignup();
            }}
            disabled={loading}
          >
            인증
          </button>
        </div>
        <SizedBox height={16} />
        <div style={styles.inputContainer}>
          <input
            style={styles.input}
            placeholder="인증번호"
            onChange={handleAuthCodeChange}
            value={authCode}
            type="number"
          />
        </div>

        <div style={styles.warning}>
          매칭 연결을 위해 카카오톡 ID를 알려주세요.
          <br /> ⚠️ID는 반드시 '검색 허용' 상태로 설정해주세요! ⚠️
        </div>

        <div style={styles.inputContainer}>
          <input
            style={styles.input}
            placeholder="카카오톡 ID"
            onChange={handleIdChange}
            value={id}
          />
        </div>
        <SizedBox height={48} />
        <button
          style={styles.submitButton}
          onClick={() => {
            onConfirm();
          }}
        >
          회원가입
        </button>
        <SizedBox height={20} />
        <div style={styles.agreement}>
          <span>연말계획의 </span>
          <span style={styles.terms}>서비스 이용약관</span>
          <span> 및 </span>
          <span style={styles.terms}>개인정보 처리방침</span>
          <span>
            에 <br />
            동의하시면 계속 진행해주세요.
          </span>
        </div>
        {/* <div style={styles.agreement}>
          연말계획의 서비스 이용약관 및 개인정보 처리방침에
          <br />
          동의하시면 계속 진행해주세요.
        </div> */}
      </div>
      {/* </form> */}
    </div>
  );
};

export default MembershipForm;
