import React, { useEffect } from "react";
import SizedBox from "./SizedBox";
import { GradientCircularProgressFromBackground } from "./DefaultIndicator";
const styles = {
  container: {
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F6EAD8",
    borderRadius: "20px",
    width: "calc(100vw - 40px)",
    maxWidth: "353px",
    // height: "305px",
    color: "#000",
    fontFamily: "Arial, sans-serif",
    boxSizing: "border-box",
    textAlign: "center",
    alignItems: "center",
  },

  title: {
    alignSelf: "flex-start",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#D26760",
    textAlign: "left",
    marginTop: "16px",
    marginLeft: "20px",
  },

  finishAcceptButton: {
    width: `184px`,
    padding: "10px 46px 10px 46px",
    borderRadius: "40px",
    border: "none",
    backgroundColor: "#D26760",
    color: "white",
    fontSize: 14,
    fontFamily: "Noto Sans KR",
    fontWeight: "700",
    wordWrap: "break-word",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  icon: {
    marginBottom: "23px",
    marginTop: "47px",
    color: "white",
    fontSize: 54,
    fontFamily: "Noto Sans KR",
    fontWeight: "500",
    wordWrap: "break-word",
  },
};
const random = (length = 8) => {
  return Math.random().toString(16).substr(2, length);
};
const MatchesComponentBeforePayment = ({
  matchData,
  targetTime,
  myUserData,
}) => {
  useEffect(() => {
    if (matchData.isPayment) {
      setIsPayment(true);
    }
    if (
      document.querySelector(`script[src="https://pay.nicepay.co.kr/v1/js/"]`)
    )
      return;
    const script = document.createElement("script");
    script.src = "https://pay.nicepay.co.kr/v1/js/";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {}, [targetTime]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isPayment, setIsPayment] = React.useState(false);

  function onClickPayments() {
    setIsLoading(true);
    window.AUTHNICE.requestPay({
      clientId: process.env.REACT_APP_PAYMENT_CLIENT_ID,
      method: process.env.REACT_APP_PAYMENT_METHOD,
      orderId: myUserData.id + random(),
      amount: process.env.REACT_APP_PAYMENT_AMOUNT,
      goodsName: process.env.REACT_APP_PAYMENT_GOODS_NAME,
      returnUrl:
        process.env.REACT_APP_PAYMENT_RETURN_URL +
        `?myId=${myUserData.id}&targetId=${matchData.id}&docId=${matchData.docId}`,
      fnError: function (result) {
        setIsLoading(false);
      },
    });
  }
  return !isLoading ? (
    matchData.isSuccess === true ? (
      <div style={styles.container}>
        <div style={styles.title}>어젯밤 인연✨</div>
        <div style={styles.icon}>🔥</div>
        {myUserData.gender === "male" &&
        (matchData.isPayment == null || matchData.isPayment === true) ? (
          <>
            <div
              style={{
                textAlign: "center",
                color: "black",
                fontSize: 14,
                fontFamily: "Noto Sans KR",
                fontWeight: "500",
                wordWrap: "break-word",
                marginBottom: 20,
              }}
            >
              어젯밤 매칭을 양측이 모두 수락했어요!
              <br />
              이제 상대의 연락처를 받아보세요 😀
            </div>
            <button
              style={styles.finishAcceptButton}
              onClick={() => {
                onClickPayments();
              }}
              disabled={isPayment}
            >
              {isPayment ? "결제 완료!" : "연락처 확인하기"}
            </button>
            <SizedBox height={40} />
          </>
        ) : (
          <>
            <div
              style={{
                textAlign: "center",
                color: "black",
                fontSize: 14,
                fontFamily: "Noto Sans KR",
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              어젯밤 매칭을 양측이 모두 수락했어요!
              <br />
              내일 21시 전까지 상대가 결제하면
              <br />
              최종 성사돼요 😀
            </div>
            <SizedBox height={84} />
          </>
        )}
      </div>
    ) : (
      <>
        <div style={styles.container}>
          <div style={styles.title}>어젯밤 인연✨</div>
          <div style={styles.icon}>🥲</div>
          <div
            style={{
              textAlign: "center",
              color: "black",
              fontSize: 14,
              fontFamily: "Noto Sans KR",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
          >
            어젯밤 매칭이 성사되지 않았어요 🥲
            <br />
            새로운 상대를 찾아드릴게요!
          </div>
          <SizedBox height={84} />
        </div>
      </>
    )
  ) : (
    <GradientCircularProgressFromBackground />
  );
};

export default MatchesComponentBeforePayment;
