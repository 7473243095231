import logo from "../assets/logo.png";
import SizedBox from "./SizedBox.jsx";
const styles = {
  foterLogoSection: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontFamily: "LINE Seed Sans KR",
    fontWeight: "700",
  },
};

const DefaultFooter = () => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "257px",
        height: "100%",
        background: "rgba(255, 255, 255, 0.20)",
        marginTop: "auto",
        paddingLeft: "22px",
        bottom: 0,
      }}
    >
      <SizedBox height={24} />
      <div style={styles.foterLogoSection}>
        <img src={logo} alt="Logo" width={24} height={24} />
        <div style={{ marginLeft: "6px" }}>연말계획</div>
      </div>
      <SizedBox height={16} />
      <div style={{ width: "100%", margin: "0 auto" }}>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 10,
            fontFamily: "Noto Sans KR",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          저스트핀(주) JustPin Inc. <br />
          대표: 장호원
          <br />
          사업자등록번호: 281-87-02874
          <br />
          주소: 서울특별시 영등포구 문래로20길 60, 709호
          <br />
          전화번호: 010-3867-7934
          <br />
          이메일: our@justpin.io
          <br />
          통신판매업 신고번호: 제 2024-서울영등포-0958호
          <br />
        </span>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 10,
            fontFamily: "Noto Sans KR",
            fontWeight: "400",
            textDecoration: "underline",
            wordWrap: "break-word",
          }}
          onClick={() => {}}
        >
          이용약관
        </span>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 10,
            fontFamily: "Noto Sans KR",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          {" "}
          및{" "}
        </span>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 10,
            fontFamily: "Noto Sans KR",
            fontWeight: "400",
            textDecoration: "underline",
            wordWrap: "break-word",
          }}
          onClick={() => {}}
        >
          개인정보처리방침
          <br />
        </span>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 10,
            fontFamily: "Noto Sans KR",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          ⓒ 2024 JustPin Inc. All rights reserved.
          <br />
          <br />
        </span>
      </div>
    </div>
  );
};

export default DefaultFooter;
