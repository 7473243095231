import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
function GradientCircularProgressFromBackground() {
  return (
    <div
      style={{
        background: "#DB8580",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Indicator />
    </div>
  );
}

function Indicator() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#DB8580" />
            <stop offset="100%" stopColor="#FFE5E2" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
      />
    </React.Fragment>
  );
}
// Modify the export statement to export each component individually
export { GradientCircularProgressFromBackground, Indicator };
